<template>
  <div>
    <header class="head">
      <nav class="navbar navbar-expand-lg p-0">
        <div class="container">
          <router-link class="navbar-brand" to="">
            <img
              src="@/assets/logo.png"
              alt="logo"
              class="img-fluid"
              style="max-width: 130px"
            />
          </router-link>
          <button
            class="navbar-toggler shadow-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mx-auto mb-2 mb-lg-0">

              <li class="nav-item" @click="closeToggle">
                <a class="nav-link active"  href="#home"
                  >Home</a
                >
              </li>
              <li class="nav-item" @click="closeToggle">
                <a class="nav-link active"  href="#about"
                  >About</a
                >
              </li>
       
              <li class="nav-item" @click="closeToggle">
                <a class="nav-link active"  href="#utility"
                  >Utility</a
                >
              </li>
              <li class="nav-item" @click="closeToggle">
                <a class="nav-link active"  href="#gallery"
                  >Gallery</a
                >
              </li>
        
              <li class="nav-item" @click="closeToggle">
                <a class="nav-link active"  href="#bots">Bots</a>
              </li>
            </ul>

            <div class="button-box d-flex gap-3">
              <a href="https://www.lbank.com/en-US/trade/gdcc_usdt/" target="_blank" class="text-decoration-none" @click="closeToggle">
                <button class="nav-button" id="btn-1">
                  <span>
                    <img
                      src="@/assets/icons/navbtnimg1.png"
                      alt="button image"
                    />
                  </span>
                  Buy GDCC
                </button>
              </a>
              <a href="https://app.chatgdcc.com/login" class="text-decoration-none" @click="closeToggle">
                <button class="nav-button" id="btn-2">
                  <span>
                    <img
                      src="@/assets/icons/navbtnimg2.png"
                      alt="button image"
                    />
                  </span>
                  Launch App
                </button>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",

  created() {
    window.addEventListener("scroll", this.handleSCroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleSCroll);
  },
  methods: {
    handleSCroll() {
      console.log(window.scrollY);
      let header = document.querySelector(".head");
      if (window.scrollY > 100 && !header.className.includes("sticky")) {
        header.classList.add("sticky");
      } else if (window.scrollY < 100) {
        header.classList.remove("sticky");
      }
    },

    closeToggle() {
      const menuToggle = document.getElementById("navbarSupportedContent");
      menuToggle.classList.remove("show");
    },
  },
};
</script>

<style scoped>
.head {
  position: fixed;
  top: 0;
  width: 100%;
}

header.head.sticky {
  position: fixed;
  top: 0 !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background: var(--white);
  z-index: 55;
}

ul.navbar-nav {
  gap: 30px;
}
ul.navbar-nav li.nav-item a.nav-link {
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  color: var(--black);
}

.nav-button {
  border: none;
  padding: 8px 26px;
  cursor: pointer;
  border-radius: 7px;
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  color: var(--white);
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 2px;
}

#btn-1 {
  background: var(--gradient);
}
#btn-2 {
  background-color: var(--green);
}


.navbar-toggler {
  border: none;
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .head {
    background: var(--white);
  }

  ul.navbar-nav {
    gap: 5px;
  }

  .button-box {
    flex-direction: column;
    margin-bottom: 15px;
  }
}

@media all and (min-width: 320px) and (max-width: 767px) {
  .head {
    background: var(--white);
  }

  ul.navbar-nav {
    gap: 5px;
  }

  .button-box {
    flex-direction: column;
    margin-bottom: 15px;
  }
}
</style>
