<template>
  <div>
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
export default {
  name: "App",

  components: {
    Header,
    Footer,
  },
};
</script>

<style>
body {
  font-family: "PlusJakartaSans" !important;
}

:root {
  --white: #ffffff;
  --black: #000;
  --blue: #0000ff;
  --purple: #9858ff;
  --green: #00b775;
  --MediumOrchid: #ba55d3;
  --purple-light: rgb(220, 176, 255);
  --light-color: rgba(255, 255, 255, 0.3);
  --plan-bg: rgb(35, 35, 47);
  --grey: rgb(164, 164, 166);
  --green-light: rgb(128, 255, 119);
  --light-purple: rgb(119, 71, 255);
  --box-shadow: rgba(0, 0, 0, 0.01) 0px 40px 16px,
    rgba(0, 0, 0, 0.05) 0px 23px 14px, rgba(0, 0, 0, 0.09) 0px 10px 10px,
    rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.1) 0px 0px 0px;
  --gradient: linear-gradient(
    94.72deg,
    rgb(254, 60, 238) 0%,
    rgb(14, 154, 197) 103.03%
  );
  --gradientone: linear-gradient(
      265.97deg,
      rgba(160, 1, 119, 0.2) 0%,
      rgba(127, 204, 237, 0.2) 58.82%
    ),
    #000000;
  --gradienttwo: radial-gradient(
    87.25% 87.11% at 1.62% 2.86%,
    #28ffcb 17.19%,
    #5d24ff 71.35%,
    #dc10b3 100%
  );

  --gradientthree: linear-gradient(
    264.42deg,
    rgb(65, 255, 152) 11.19%,
    rgb(18, 141, 209) 41.2%,
    rgb(104, 0, 185) 85.99%
  );
  --gradinetfour: linear-gradient(
      rgb(39, 220, 238) 0%,
      rgba(40, 87, 255, 0.725) 27.6%,
      rgba(155, 102, 131, 0.455) 54.69%,
      rgba(155, 102, 131, 0.455) 54.7%,
      rgba(199, 17, 237, 0.27) 72.92%,
      rgba(251, 4, 43, 0) 100%
    ),
    linear-gradient(0deg, rgb(255, 115, 217), rgb(255, 115, 217)),
    rgb(17, 17, 24);

  --gradientfive: linear-gradient(
      rgba(39, 220, 238, 0.2) 0%,
      rgba(40, 87, 255, 0.145) 27.6%,
      rgba(155, 102, 131, 0.09) 54.69%,
      rgba(155, 102, 131, 0.09) 54.7%,
      rgba(199, 17, 237, 0.055) 72.92%,
      rgba(251, 4, 43, 0) 100%
    ),
    linear-gradient(0deg, rgba(255, 115, 217, 0.2), rgba(255, 115, 217, 0.2)),
    rgb(17, 17, 24);
  --gradient-six: linear-gradient(
    180deg,
    #563eec 0%,
    #6b2487 80%,
    #68338e 100%
  );
  --gradient-seven: linear-gradient(
    180deg,
    #3eece1 0%,
    #386bcf 25%,
    #632678 100%
  );
  --gradient-eight: linear-gradient(
    180deg,
    #ec3e5d 0%,
    #cf385e 25%,
    #782661 100%
  );
  --gradient-nine: linear-gradient(
    180deg,
    #e7c8ad 0%,
    #bf844f 67.19%,
    #ad7b59 100%
  );
  --gradient-ten: linear-gradient(
    180deg,
    #3fab5d 0%,
    #3df4a7 39.86%,
    #4dbdd6 100%
  );
  --footer-bg: rgb(249, 249, 249);
  --footer-input: rgb(218, 218, 218);
}

@font-face {
  font-family: PlusJakartaSans;
  src: url(@/assets/fonts/PlusJakartaSans-Regular.ttf);
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: PlusJakartaSans;
  src: url(@/assets/fonts/PlusJakartaSans-Medium.ttf);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: PlusJakartaSans;
  src: url(@/assets/fonts/PlusJakartaSans-SemiBold.ttf);
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: PlusJakartaSans;
  src: url(@/assets/fonts/PlusJakartaSans-Bold.ttf);
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: PlusJakartaSans;
  src: url(@/assets/fonts/PlusJakartaSans-ExtraBold.ttf);
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Infra;
  src: url(@/assets/fonts/InfraRegular.otf);
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Infra;
  src: url(@/assets/fonts/InfraMedium.otf);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Infra;
  src: url(@/assets/fonts/InfraSemiBold.otf);
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Infra;
  src: url(@/assets/fonts/InfraBold.otf);
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Infra;
  src: url(@/assets/fonts/InfraExtraBold.otf);
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Infra;
  src: url(@/assets/fonts/InfraBlack.otf);
  font-weight: 900;
  font-display: swap;
}

section {
  padding: 80px 0;
}

.swiper-slide img {
  display: block;
  width: 600px;
}



@media all and (min-width: 992px) and (max-width: 1199px) {
  .plan-price h4 br {
    display: none;
  }
}

@media all and (min-width: 768px) and (max-width: 991px) {
  section {
    padding: 40px 0;
  }

  .plan-price h4 br {
    display: none;
}
}

@media all and (min-width: 320px) and (max-width: 767px) {
  section {
    padding: 40px 0;
  }

  .bots-info-box h3 br {
    display: none;
  }

  .plan-price h4 br {
    display: none;
  }
}
</style>
