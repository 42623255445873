<template>
  <div>
    <section class="banner-sec" id="home">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xl-6">
            <div class="banner-info">
              <h1 class="mb-0">
                Chat, Create, Captivate: GDCC's AI Content Generation Hub
              </h1>

              <span class="mb-0">
                Experience boundless creativity with GDCC – Your all-in-one AI
                platform <br />
                for effortless content generation. From dynamic chat
                interactions to <br />
                stunning image creation, empower your brand like never before.
              </span>
            </div>
            <!--banner-info-->

            <div class="button-heading mb-3">
              <h2 class="mb-0">Try GDCC AI Bots!</h2>
            </div>
            <!--button-heading-->

            <div class="button-box mb-3">
              <a href="#" class="text-decoration-none">
                <button class="banner-btn" id="btn-1">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path
                        d="M6 21H3a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1zm7 0h-3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v17a1 1 0 0 1-1 1zm7 0h-3a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1z"
                      ></path>
                    </svg>
                  </span>
                  Live Chart
                </button>
              </a>

              <a href="#" class="text-decoration-none">
                <button class="banner-btn" id="btn-2">
                  <span>
                    <img
                      src="../assets/icons/navbtnimg2.png"
                      alt="wallet-icon"
                    />
                  </span>
                  Launch App
                </button>
              </a>
            </div>
            <!--button-box-->

            <div class="button-box">
              <a href="#" class="text-decoration-none">
                <button class="banner-btn" id="btn-3">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path
                        d="M14.82 4.26a10.14 10.14 0 0 0-.53 1.1 14.66 14.66 0 0 0-4.58 0 10.14 10.14 0 0 0-.53-1.1 16 16 0 0 0-4.13 1.3 17.33 17.33 0 0 0-3 11.59 16.6 16.6 0 0 0 5.07 2.59A12.89 12.89 0 0 0 8.23 18a9.65 9.65 0 0 1-1.71-.83 3.39 3.39 0 0 0 .42-.33 11.66 11.66 0 0 0 10.12 0q.21.18.42.33a10.84 10.84 0 0 1-1.71.84 12.41 12.41 0 0 0 1.08 1.78 16.44 16.44 0 0 0 5.06-2.59 17.22 17.22 0 0 0-3-11.59 16.09 16.09 0 0 0-4.09-1.35zM8.68 14.81a1.94 1.94 0 0 1-1.8-2 1.93 1.93 0 0 1 1.8-2 1.93 1.93 0 0 1 1.8 2 1.93 1.93 0 0 1-1.8 2zm6.64 0a1.94 1.94 0 0 1-1.8-2 1.93 1.93 0 0 1 1.8-2 1.92 1.92 0 0 1 1.8 2 1.92 1.92 0 0 1-1.8 2z"
                      ></path>
                    </svg>
                  </span>
                  Discord Bot
                </button>
              </a>

              <a href="#" class="text-decoration-none">
                <button class="banner-btn" id="btn-4">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path
                        d="m20.665 3.717-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42 10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701h-.002l.002.001-.314 4.692c.46 0 .663-.211.921-.46l2.211-2.15 4.599 3.397c.848.467 1.457.227 1.668-.785l3.019-14.228c.309-1.239-.473-1.8-1.282-1.434z"
                      ></path>
                    </svg>
                  </span>
                  Telegram Bot
                </button>
              </a>
            </div>
            <!--button-box-->
          </div>
          <!--col-md-6 col-lg-6 col-xl-6-->

          <div class="col-md-6 col-lg-6 col-xl-6 order-first order-sm-1">
            <div class="banner-image">
              <img
                src="../assets/images/banner.webp"
                alt="banner"
                class="img-fluid"
              />
            </div>
            <!--banner-image-->
          </div>
        </div>
        <!--row-->
      </div>
      <!--container-->
    </section>
    <!--banner-sec-->

    <section class="showcase-sec">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12">
            <div class="slider-box">
              <swiper
                :effect="'coverflow'"
                :grabCursor="true"
                :centeredSlides="true"
                :loop="true"
                :slidesPerView="2"
                :autoplay="{
                  delay: 2500,
                  disableOnInteraction: false,
                }"
                :coverflowEffect="{
                  rotate: 40,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: false,
                }"
                :pagination="true"
                :modules="modules"
                class="mySwiper"
              >
                <swiper-slide
                  ><img
                    src="../assets/images/slide1.jpg"
                    alt="slide-img"
                    class="img-fluid"
                    loading="lazy"
                /></swiper-slide>

                <swiper-slide
                  ><img
                    src="../assets/images/slide2.jpg"
                    alt="slide-img"
                    class="img-fluid"
                    loading="lazy"
                /></swiper-slide>

                <swiper-slide
                  ><img
                    src="../assets/images/slide3.jpg"
                    alt="slide-img"
                    class="img-fluid"
                    loading="lazy"
                /></swiper-slide>

                <swiper-slide
                  ><img
                    src="../assets/images/slide4.jpg"
                    alt="slide-img"
                    class="img-fluid"
                    loading="lazy"
                /></swiper-slide>

                <swiper-slide
                  ><img
                    src="../assets/images/slide5.jpg"
                    alt="slide-img"
                    class="img-fluid"
                    loading="lazy"
                /></swiper-slide>

                <swiper-slide
                  ><img
                    src="../assets/images/slide6.jpg"
                    alt="slide-img"
                    class="img-fluid"
                    loading="lazy"
                /></swiper-slide>
              </swiper>
            </div>
          </div>
          <!--col-md-12 col-lg-12 col-xl-12-->
        </div>
        <!--row-->
      </div>
      <!--container-->
    </section>
    <!--showcase-sec-->

    <section class="bots-sec" id="bots">
      <div class="container">
        <div class="inner-wrapper mb-5" id="warpper-one">
          <div class="upper-warpper"></div>
          <div class="row align-items-center position-relative">
            <div class="col-md-6 col-lg-6 col-xl-6 mb-5">
              <div class="heading-box">
                <h2 class="mb-0">
                  Designed to Moon Harder <br />
                  & Onboard Users To The <br />
                  GDCC Ecosystem
                </h2>
              </div>
              <!--heading-box-->
            </div>
            <!--col-md-6 col-lg-6 col-xl-6-->

            <div class="col-md-6 col-lg-6 col-xl-6 mb-5">
              <div class="social-list">
                <ul class="list">
                  <li>
                    <a href="" class="text-decoration-none">
                      <img
                        src="../assets/icons/telegram.png"
                        alt="icons"
                        class="img-fluid"
                      />
                    </a>
                  </li>

                  <li>
                    <a href="" class="text-decoration-none">
                      <img
                        src="../assets/icons/youtube.png"
                        alt="icons"
                        class="img-fluid"
                      />
                    </a>
                  </li>

                  <li>
                    <a href="" class="text-decoration-none">
                      <img
                        src="../assets/icons/twitter.png"
                        alt="icons"
                        class="img-fluid"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <!--social-list-->
            </div>
            <!--col-md-6 col-lg-6 col-xl-6-->

            <div class="col-md-12 col-lg-12 col-xl-12">
              <div class="button-list-box">
                <ul class="button-list">
                  <li>
                    <a href="https://www.gdccscan.io/" target="_blank" class="text-decoration-none">
                      <button class="ecosystem-button">View Chart</button>
                    </a>
                  </li>

                  <li>
                    <a href="https://www.gdccscan.io/" target="_blank" class="text-decoration-none">
                      <button class="ecosystem-button">Check GDCC Scan</button>
                    </a>
                  </li>

                  <li>
                    <a href="https://www.gdccscan.io/" target="_blank" class="text-decoration-none">
                      <button class="ecosystem-button">Claim GDCC</button>
                    </a>
                  </li>

                  <li>
                    <a href="https://www.lbank.com/en-US/trade/gdcc_usdt/" target="_blank" class="text-decoration-none">
                      <button class="ecosystem-button">Buy GDCC</button>
                    </a>
                  </li>
                </ul>
              </div>
              <!--button-list-box-->
            </div>
            <!--col-md-12 col-lg-12 col-xl-12-->
          </div>
          <!--row-->
        </div>
        <!--inner-wrapper warpper-one-->

        <div class="inner-wrapper mb-5" id="wrapper-two">
          <div class="row align-items-center justify-content-between">
            <div class="col-md-6 col-lg-6 col-xl-5">
              <div class="chatai-heading">
                <h3 class="mb-0">Chat With GDCC AI</h3>

                <p class="mb-0">
                  Welcome to GDCC, your gateway to the future of content
                  creation. Our AI-powered platform redefines content
                  generation, making it smarter and more efficient. With GDCC,
                  you can effortlessly produce high-quality written content,
                  tailored to your needs. Whether you're a blogger, marketer, or
                  business owner, our chat-based interface streamlines the
                  creative process. Say goodbye to writer's block and hello to
                  engaging, AI-generated content that resonates with your
                  audience. Join us in shaping the next generation of content
                  creation, where innovation and efficiency converge to empower
                  your brand and elevate your messaging. GDCC – where words come
                  to life with the power of AI.
                </p>

                <a href="#" class="text-decoration-none">
                  <button class="openchatp-btn">
                    Open Chat Box

                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </a>
              </div>
              <!--chatai-heading-->
            </div>
            <!--col-md-6 col-lg-6 col-xl-6-->

            <div class="col-md-6 col-lg-6 col-xl-6 order-first order-sm-1">
              <div class="chatai-image">
                <img
                  src="../assets/images/chatai.png"
                  alt="chatai image"
                  class="img-fluid"
                />
              </div>
            </div>
            <!--col-md-6 col-lg-6 col-xl-6-->
          </div>
          <!--row-->
        </div>
        <!--inner-wrapper warpper-two-->
        <div class="inner-wrapper mb-5" id="wrapper-three">
          <div class="row align-items-center justify-content-between">
            <div class="col-md-6 col-lg-6 col-xl-6">
              <div class="chatai-image" id="imageai-img">
                <img
                  src="../assets/images/imagegenrator.png"
                  alt="chatai image"
                  class="img-fluid"
                />
              </div>
            </div>
            <!--col-md-6 col-lg-6 col-xl-6-->

            <div class="col-md-6 col-lg-6 col-xl-5">
              <div class="chatai-heading" id="imageai-info">
                <h3 class="mb-0">AI Image Generator</h3>

                <p class="mb-0">
                  Welcome to GDCC, your portal to AI-powered visual
                  storytelling. Our cutting-edge platform transforms text into
                  captivating images with ease. Whether you're a content
                  creator, marketer, or business owner, GDCC empowers you to
                  craft stunning visuals that resonate with your audience. Break
                  free from the constraints of stock photos and graphic design
                  skills. With GDCC, you can effortlessly create unique,
                  eye-catching images that enhance your brand and messaging.
                  Join us on a journey of creativity and innovation, where AI
                  meets visual content creation. Discover the future of image
                  generation at GDCC and elevate your visual branding to new
                  heights.
                </p>

                <div class="image-button">
                  <a href="#" class="text-decoration-none">
                    <button class="openchatp-btn">Open Chat Box</button>
                  </a>
                  <a href="#" class="text-decoration-none">
                    <button class="openchatp-btn">Telegram Bot</button>
                  </a>
                </div>
                <!--image-button-->
              </div>
              <!--chatai-heading-->
            </div>
            <!--col-md-6 col-lg-6 col-xl-6-->
          </div>
          <!--row-->
        </div>
        <!--inner-wrapper warpper-three-->
        <div class="inner-wrapper mb-5" id="wrapper-four">
          <div class="row align-items-center justify-content-between">
            <div class="col-md-6 col-lg-6 col-xl-6">
              <div class="chatai-image text-center" id="app-img">
                <img
                  src="../assets/images/app.png"
                  alt="chatai image"
                  class="img-fluid"
                />
              </div>
            </div>
            <!--col-md-6 col-lg-6 col-xl-6-->

            <div class="col-md-6 col-lg-6 col-xl-5">
              <div class="chatai-heading" id="app-info">
                <h3 class="mb-0">GDCC App</h3>

                <p class="mb-0">
                  Welcome to GDCC, your gateway to the future of AI-driven applications. Our platform empowers you to create intelligent, chat-based apps that redefine user experiences. Whether you're a developer, entrepreneur, or business owner, GDCC simplifies app development with its intuitive interface and robust AI capabilities. Harness the power of conversational AI to build chatbots, virtual assistants, and more, all customized to meet your specific needs. GDCC is your partner in innovation, streamlining app development and enhancing user engagement. Join us in shaping the next generation of AI applications. Experience the limitless possibilities of GDCC and unlock the full potential of chat-based AI. Welcome to the future of app development with GDCC.
                </p>

                <div class="image-button" id="app-button">
                  <span>Open Chat Box</span>

                  <a href="#" class="text-decoration-none">
                    <button class="openchatp-btn">
                      <span
                        ><img
                          src="../assets/icons/google-play-logo.svg"
                          alt="google icon"
                          class="img-fluid"
                      /></span>
                      Google Play
                    </button>
                  </a>
                </div>
                <!--image-button-->
              </div>
              <!--chatai-heading-->
            </div>
            <!--col-md-6 col-lg-6 col-xl-6-->
          </div>
          <!--row-->
        </div>
        <!--inner-wrapper warpper-four-->

        <div class="inner-wrapper" id="wrapper-five">
          <div class="row align-items-center">
            <div
              class="col-md-6 col-lg-6 col-xl-6 mb-3"
              v-for="(price, index) in PriceData"
              :key="index"
            >
              <div class="price-box">
                <h3 class="mb-0">{{ price.title }}</h3>

                <ul class="plan-list">
                  <li
                    class="mb-2"
                    v-for="(list, index) in price.ListData"
                    :key="index"
                  >
                    <span
                      ><svg
                        stroke="currentColor"
                        fill="none"
                        stroke-width="0"
                        viewBox="0 0 15 15"
                        class="bg-[#777786]"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.875 7.5C9.875 8.81168 8.81168 9.875 7.5 9.875C6.18832 9.875 5.125 8.81168 5.125 7.5C5.125 6.18832 6.18832 5.125 7.5 5.125C8.81168 5.125 9.875 6.18832 9.875 7.5Z"
                          fill="currentColor"
                        ></path></svg
                    ></span>

                    {{ list.para }}
                  </li>
                </ul>

                <div class="plan-price">
                  <h4 class="mb-0" v-html="price.price"></h4>

                  <button class="demo-btn" :id="price.id">
                    {{ price.name }}
                  </button>
                </div>
              </div>
              <!--price-box-->
            </div>
            <!--col-md-6 col-lg-6 col-xl-6-->
          </div>
          <!--row align-items-center-->
        </div>
        <!--inner-wrapper wrapper-five-->
      </div>
      <!--container-->
    </section>
    <!--bots-sec-->

    <section class="about-sec" id="about">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xl-6">
            <div class="about-info">
              <h3 class="mb-0 pb-3">ABOUT US</h3>
              <p class="mb-0">
                Welcome to GDCC, your all-in-one destination for cutting-edge
                AI-driven content creation. Our platform seamlessly integrates
                chat and image content generation to empower your creativity
                like never before. Whether you're a marketer, blogger, or
                business owner, GDCC revolutionizes the way you engage with your
                audience. With GDCC, crafting compelling content becomes a
                breeze. Our chat interface allows you to effortlessly generate
                dynamic conversations tailored to your needs. These
                conversations can then be transformed into stunning, custom
                images that elevate your visual storytelling. Say goodbye to the
                hassle of sourcing stock photos or struggling with graphic
                design tools—GDCC simplifies it all. Harness the power of AI to
                produce content that resonates with your target audience. Our
                platform understands your unique voice and helps you deliver
                messages that captivate and convert. Whether it's for your
                website, social media, or marketing campaigns, GDCC is your
                partner in content excellence. Join us in shaping the future of
                content creation. Break free from creative constraints and
                unlock limitless possibilities. Discover the synergy of chat and
                image generation at GDCC and elevate your brand's communication
                to new heights. Experience the future of content creation today.
              </p>
            </div>
            <!--about-info-->
          </div>
          <!--col-md-6 col-lg-6 col-xl-6-->

          <div class="col-md-6 col-lg-6 col-xl-6">
            <div class="about-list-box">
              <ul class="about-list">
                <li class="mb-3">
                  <div class="about-list-info">
                    <div class="about-list-icon">
                      <img
                        src="../assets/icons/about-icon-1.webp"
                        alt="about icon"
                        class="img-fluid"
                      />
                    </div>
                    <!--about-list-icon-->

                    <div class="about-list-text">
                      <span style="color: #69b752"> 1% </span>
                      <p class="mb-0 mt-2">
                        Of Each Transaction Added To <br />
                        Liquidity Pool
                      </p>
                    </div>
                    <!--about-list-text-->
                  </div>
                </li>

                <li class="mb-3">
                  <div class="about-list-info">
                    <div class="about-list-icon">
                      <img
                        src="../assets/icons/about-icon-2.webp"
                        alt="about icon"
                        class="img-fluid"
                      />
                    </div>
                    <!--about-list-icon-->

                    <div class="about-list-text">
                      <span style="color: #ee664d"> 1% </span>
                      <p class="mb-0 mt-2">Marketing</p>
                    </div>
                    <!--about-list-text-->
                  </div>
                </li>

                <li class="mb-3">
                  <div class="about-list-info">
                    <div class="about-list-icon">
                      <img
                        src="../assets/icons/about-icon-3.webp"
                        alt="about icon"
                        class="img-fluid"
                      />
                    </div>
                    <!--about-list-icon-->

                    <div class="about-list-text">
                      <span style="color: #74efc5"> 1% </span>
                      <p class="mb-0 mt-2">AI Dev Fund</p>
                    </div>
                    <!--about-list-text-->
                  </div>
                </li>

                <li>
                  <div class="about-list-info">
                    <div class="about-list-icon">
                      <img
                        src="../assets/icons/about-icon-1.webp"
                        alt="about icon"
                        class="img-fluid"
                      />
                    </div>
                    <!--about-list-icon-->

                    <div class="about-list-text">
                      <span style="color: #4d52ff"> 25% </span>
                      <p class="mb-0 mt-2">
                        Of Each NFT Transaction Will <br />
                        Be Used For Buy Back And Burn
                      </p>
                    </div>
                    <!--about-list-text-->
                  </div>
                </li>
              </ul>
            </div>
            <!--about-list-box-->
          </div>
          <!--col-md-6 col-lg-6 col-xl-6-->
        </div>
        <!--row-->
      </div>
      <!--container-->
    </section>
    <!--about-sec-->

    <section class="utility-sec" id="utility">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12 mb-5">
            <div class="heading">
              <h3 class="mb-0">Utility</h3>
            </div>
            <!--heading-->
          </div>
          <!--col-md-12 col-lg-12 col-xl-12-->

          <div class="col-md-12 col-lg-10 col-xl-7 mx-auto">
            <div class="accordion" id="accordionExample">
              <div
                class="accordion-item"
                v-for="(accordion, index) in AccordionData"
                :key="index"
              >
                <h2 class="accordion-header">
                  <button
                    class="shadow-none"
                    :class="
                      index == 0
                        ? 'accordion-button'
                        : 'accordion-button collapsed'
                    "
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#collapseOne' + index"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    {{ accordion.title }}
                  </button>
                </h2>
                <div
                  :id="'collapseOne' + index"
                  :class="
                    index == 0
                      ? 'accordion-collapse collapse show'
                      : 'accordion-collapse collapse'
                  "
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <ul class="accordion-list mb-3">
                      <li
                        class="mb-2"
                        v-for="(
                          accordionlist, index
                        ) in accordion.AccordionListData"
                        :key="index"
                      >
                        <p class="mb-0">
                          <span>
                            <svg
                              stroke="currentColor"
                              fill="none"
                              stroke-width="0"
                              viewBox="0 0 15 15"
                              class="bg-[#777786]"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.875 7.5C9.875 8.81168 8.81168 9.875 7.5 9.875C6.18832 9.875 5.125 8.81168 5.125 7.5C5.125 6.18832 6.18832 5.125 7.5 5.125C8.81168 5.125 9.875 6.18832 9.875 7.5Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </span>

                          {{ accordionlist.listpara }}
                        </p>
                      </li>
                    </ul>

                    <p class="mb-0">
                      {{ accordion.para }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--utility-sec-->

    <section class="wrapper-sec">
      <div class="container">
        <div
          class="wrapper-box mb-3"
          :id="bots.id"
          v-for="(bots, index) in BotsData"
          :key="index"
        >
          <div class="row align-items-center">
            <div class="col-md-6 col-lg-6 col-xl-6">
              <div class="bots-info-box">
                <h3 class="mb-0" v-html="bots.title"></h3>
              </div>
              <!--bots-info-box-->
            </div>
            <!--col-md-6 col-lg-6 col-xl-6-->

            <div class="col-md-6 col-lg-6 col-xl-6">
              <div class="bots-image">
                <img
                  :src="require(`../assets/images/${bots.image}`)"
                  alt="bot image"
                  class="img-fluid"
                  loading="lazy"
                />
              </div>
              <!--bots-image-->
            </div>
            <!--col-md-6 col-lg-6 col-xl-6-->
          </div>
          <!--row align-items-center-->
        </div>
        <!--wrapper-box-->

        <div class="wrapper-box" id="bots-wrapper-five">
          <div class="row align-items-center">
            <div class="col-md-6 col-lg-6 col-xl-6">
              <div class="bots-info-box">
                <h3 class="mb-0">Partnership</h3>
                <p class="mb-0">
                  Possible partnership: Artist, Company, <br />
                  Coin/Token, Metaverse, Gaming or <br />
                  Influencer.
                </p>
              </div>
              <!--bots-info-box-->
            </div>
            <!--col-md-6 col-lg-6 col-xl-6-->

            <div class="col-md-6 col-lg-6 col-xl-5">
              <div class="form-box">
                <form class="row form-row">
                  <div class="col-md-12 col-lg-6 col-xl-6 mb-3">
                    <div class="label mb-2">
                      <label>Name*</label>
                    </div>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control shadow-none"
                        placeholder="Enter your name......"
                        aria-label="name"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>

                  <div class="col-md-12 col-lg-6 col-xl-6 mb-3">
                    <div class="label mb-2">
                      <label>Email*</label>
                    </div>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control shadow-none"
                        placeholder="Enter your email......"
                        aria-label="email"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>

                  <div class="col-md-12 col-lg-12 col-xl-12 mb-3">
                    <div class="label mb-2">
                      <label>Subject*</label>
                    </div>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control shadow-none"
                        placeholder="Enter your subject......"
                        aria-label="email"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>

                  <div class="col-md-12 col-lg-12 col-xl-12 mb-3">
                    <div class="label mb-2">
                      <label>Write a Message</label>
                    </div>

                    <div class="input-group">
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="5"
                        placeholder="Message......"
                      ></textarea>
                    </div>
                  </div>
                  <!--col-md-12 col-lg-12 col-xl-12-->

                  <div class="col-md-12 col-lg-12 col-xl-12">
                    <div class="submit-button">
                      <button class="submit-button">Submit Your Message</button>
                    </div>
                  </div>
                  <!--col-md-12 col-lg-12 col-xl-12-->
                </form>
              </div>
              <!--form-box-->
            </div>
            <!--col-md-6 col-lg-6 col-xl-6-->
          </div>
          <!--row-->
        </div>
        <!--wrapper-box bots-wrapper-five-->
      </div>
      <!--container-->
    </section>
    <!--wrapper-sec-->

    <section class="gallery-sec" id="gallery">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12 mb-5">
            <div class="heading">
              <h3 class="mb-0 pb-2">GDCC Gallery</h3>
            </div>
            <!--heading-->
          </div>
          <!--col-md-12 col-lg-12 col-xl-12-->

          <div
            class="col-md-4 col-lg-4 col-xl-4 mb-3"
            v-for="(gallery, index) in GalleyData"
            :key="index"
          >
            <div class="galery-image">
              <img
                :src="require(`../assets/images/${gallery.image}`)"
                alt="gallery image"
                class="img-fluid"
                loading="lazy"
              />
            </div>
          </div>
          <!--col-md-4 col-lg-4 col-xl-4-->
        </div>
        <!--row-->
      </div>
      <!--container-->
    </section>
    <!--gallery-sec-->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";

import "swiper/css/effect-coverflow";
import { EffectCoverflow, Autoplay } from "swiper/modules";
export default {
  name: "HomeView",

  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      PriceData: [
        {
          title: "Free Plan",
          ListData: [
            {
              para: "Access to Discord and Telegram Servers",
            },
            {
              para: "Access to Web App",
            },
            {
              para: "Generate 2x AI Images",
            },
            {
              para: "Generate up to 1000 words of AI Text",
            },
          ],

          price: "$0/Month",
          name: "Try Demo",
        },

        {
          title: "Basic Plan",
          ListData: [
            {
              para: "Everything in Free Plan",
            },
            {
              para: "Access to Mobile App",
            },
            {
              para: "Generate Unlimited AI Images",
            },
            {
              para: "Generate up to 10K words of AI Text per day",
            },
          ],

          price: "$10 / Month or Hold <br/> 100K GDCC AI",
          id: "demotwo",
          name: "Select Plan",
        },

        {
          title: "Standard Plan",
          ListData: [
            {
              para: "Everything in Basic Plan",
            },
            {
              para: "Access to Private Telegram Chat",
            },
            {
              para: "Generate Unlimited AI Images",
            },
            {
              para: "Generate up to 30K words of AI Text per day",
            },
          ],

          price: "$25 / Month or Hold <br/> 250K GDCC AI",
          id: "demotwo",
          name: "Select Plan",
        },

        {
          title: "Premium Plan",
          ListData: [
            {
              para: "Everything in Standard Plan",
            },
            {
              para: "Add Bots to your own Servers",
            },
            {
              para: "Generate Unlimited AI Images",
            },
            {
              para: "Generate up to 2M words of AI Text/month",
            },
          ],

          price: "$50 / Month or Hold <br/> 500K GDCC AI",
          id: "demotwo",
          name: "Select Plan",
        },
      ],

      AccordionData: [
        {
          title: "Bot Features",
          AccordionListData: [
            {
              listpara: "AI Chat",
            },
            {
              listpara: "Text to Image Generator",
            },
            {
              listpara: "Image to Image Generator",
            },
            {
              listpara: "More than 10 different models/styles",
            },
          ],
        },

        {
          title: "Telegram Features",
          AccordionListData: [
            {
              listpara: "Telegram Community Bot",
            },
            {
              listpara: "Telegram Private Bot",
            },
            {
              listpara:
                "Premium Users can add Telegram bot to their own groups",
            },
          ],
        },

        {
          title: "Discord Features",
          AccordionListData: [
            {
              listpara: "Discord Community Bot",
            },
            {
              listpara:
                "Premium Users can add Discord bot to their own servers",
            },
          ],
        },

        {
          title:
            "Web version with voice-enabled commands Android App with voice-enabled commands",
          para: "One Account connectivity with all platforms",
        },
      ],

      BotsData: [
        {
          title: " GDCC AI <br /> Buy Bot & Raid Bot",
          image: "bot-1.webp",
          id: "bots-wrapper-one",
        },

        {
          title: " GDCC AI <br /> Play 2 Earn",
          image: "bot-2.webp",
          id: "bots-wrapper-two",
        },

        {
          title: " GDCC AI <br /> Store",
          image: "bot-3.webp",
          id: "bots-wrapper-three",
        },

        {
          title: " GDCC AI <br /> NFTs & NFT Generator",
          image: "bot-4.webp",
          id: "bots-wrapper-four",
        },
      ],

      GalleyData: [
        {
          image: "gallery-1.jpg",
        },

        {
          image: "gallery-2.jpg",
        },

        {
          image: "gallery-3.jpg",
        },

        {
          image: "gallery-4.jpg",
        },

        {
          image: "gallery-5.jpg",
        },

        {
          image: "gallery-6.jpg",
        },
      ],
    };
  },

  setup() {
    return {
      modules: [EffectCoverflow, Autoplay],
    };
  },
};
</script>

<style scoped>
/**======================= BANNER SEC CSS START =======================**/

.banner-sec {
  padding-top: 130px;
}
.banner-info {
  margin-bottom: 30px;
}
.banner-info h1 {
  text-transform: uppercase;
  font-size: 40px;
  line-height: 54px;
  font-family: "Infra";
  font-weight: 800;
  padding-bottom: 10px;
  color: var(--black);
}

.banner-info span {
  font-size: 18px;
  font-weight: 400;
  color: var(--black);
  font-family: "Infra";
  opacity: 0.8;
  display: inline-block;
  line-height: 30px;
}

.button-heading h2 {
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Infra";
  color: var(--black);
}

.button-box {
  display: flex;
  align-items: center;
  gap: 20px;
}

.button-box button.banner-btn {
  border: none;
  padding: 8px 26px;
  cursor: pointer;
  border-radius: 7px;
  box-shadow: var(--box-shadow);
  color: var(--black);
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 6px;
  min-width: 170px;
  background-color: var(--white);
  min-height: 45px;
}

#btn-1 {
  background-color: var(--purple);
  color: var(--white);
}

#btn-2 {
  background-color: var(--green);
  color: var(--white);
}

#btn-3 {
  color: var(--MediumOrchid);
}

#btn-4 {
  color: var(--MediumOrchid);
}

#btn-3 span svg {
  fill: #5865f2;
}
#btn-4 span svg {
  fill: #2aabee;
}

.button-box button.banner-btn span svg {
  width: 20px;
  height: 20px;
  fill: var(--white);
}

.banner-image {
  text-align: center;
}
/**======================= BANNER SEC CSS FINISH =======================**/

/**======================= BOTS SEC CSS START =======================**/

.inner-wrapper {
  padding: 64px;
  border-radius: 10px;
}

div#warpper-one {
  background: var(--gradientone);
  position: relative;
}

.upper-warpper {
  background: var(--gradienttwo);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  opacity: 0.5;
}

.heading-box h2 {
  font-size: 45px;
  font-family: "Infra";
  font-weight: 800;
  line-height: 57px;
  color: var(--white);
  mix-blend-mode: overlay;
  opacity: 1;
}

.social-list ul.list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: end;
}

.button-list-box ul.button-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

ul.button-list li a button.ecosystem-button {
  min-width: 230px;
  min-height: 55px;
  padding: 10px 10px;
  border-radius: 45px;
  border: none;
  font-size: 22px;
  font-weight: 700;
  font-family: "Infra";
  color: var(--black);
  background: var(--white);
  transition: all 0.5s ease;
  box-shadow: var(--box-shadow);
}

ul.button-list li a button.ecosystem-button:hover {
  background: var(--light-color);
  transition: all 0.5s ease;
  color: var(--white);
}

#wrapper-two {
  background: var(--gradientthree);
}

.chatai-heading h3 {
  font-size: 36px;
  font-weight: 700;
  font-family: "Infra";
  padding-bottom: 15px;
  color: var(--white);
}

.chatai-heading p {
  color: var(--white);
  font-weight: 400;
  font-family: "Infra";
  line-height: 25px;
  padding-bottom: 20px;
}

.chatai-heading button.openchatp-btn {
  box-shadow: var(--box-shadow);
  min-height: 50px;
  min-width: 180px;
  border-radius: 5px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  background-color: var(--black);
  color: var(--purple-light);
}

.chatai-heading button.openchatp-btn span svg {
  fill: var(--purple-light);
}

#wrapper-three {
  background: var(--gradinetfour);
}

.image-button {
  display: flex;
  align-items: center;
  gap: 20px;
}

#wrapper-four {
  background: var(--gradinetfour);
}

#app-info span {
  color: var(--white);
  font-size: 17px;
  font-weight: 700;
}

#app-info span img {
  width: 20px;
  height: 20px;
}

#wrapper-five {
  background: var(--gradientfive);
}

.price-box {
  padding: 48px;
  background: var(--plan-bg);
  border-radius: 20px;
}

.price-box h3 {
  font-family: "Infra";
  font-weight: 800;
  color: var(--white);
  font-size: 30px;
  padding-bottom: 30px;
}

ul.plan-list {
  padding: 0;
  list-style: none;
  margin-bottom: 60px;
}

ul.plan-list li {
  color: var(--grey);
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.bg-\[\#777786\] {
  --tw-bg-opacity: 1;
  background-color: rgb(119 119 134 / var(--tw-bg-opacity));
}

.plan-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plan-price h4 {
  font-family: "Infra";
  font-weight: 800;
  color: var(--white);
  font-size: 20px;
}

.plan-price button.demo-btn {
  min-height: 55px;
  min-width: 170px;
  border-radius: 5px;
  border: none;
  background-color: var(--green-light);
  font-size: 20px;
  font-family: "Infra";
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
}

button#demotwo {
  background-color: var(--light-purple);
  color: var(--white);
}
/**======================= BOTS SEC CSS FINISH =======================**/

/**======================= ABOUT SEC CSS START =======================**/

.about-info h3 {
  font-family: "Infra";
  font-weight: 700;
  color: var(--black);
  font-size: 30px;
}

.about-info p {
  font-weight: 500;
  line-height: 26px;
  font-size: 16px;
  color: var(--black);
}

.about-list-box {
  display: flex;
  justify-content: end;
}

.about-list-box ul.about-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.about-list-box ul.about-list li .about-list-info {
  display: flex;
  align-items: baseline;
  gap: 20px;
}

.about-list-text span {
  font-size: 45px;
  font-weight: 800;
}

.about-list-text p {
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  color: var(--black);
}
/**======================= ABOUT SEC CSS FINISH =======================**/

/**======================= UTILITY SEC CSS START =======================**/
.heading h3 {
  font-size: 50px;
  font-weight: 800;
  font-family: "Infra";
  color: var(--black);
  text-align: center;
}

.accordion-item {
  background: var(--white);
  border-left: none;
  border-right: none;
  border-bottom: 2px solid var(--black);
  border-top: none;
  margin-bottom: 20px;
}

.accordion-item:last-of-type {
  border-radius: 0;
}

.accordion-item:first-of-type {
  border-radius: 0;
}

.accordion-button {
  border: none;
  background-color: var(--white);
  font-size: 20px;
  font-family: "Infra";
  font-weight: 700;
  color: var(--black);
}

.accordion-button:not(.collapsed) {
  background-color: var(--white);
  color: var(--black);
}

.accordion-button::after {
  background-image: url(../assets/icons/arrow-down.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 36px;
  height: 36px;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(../assets/icons/arrow-up.png);
  transform: none;
}

ul.accordion-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.accordion-list li p {
  font-size: 16px;
  font-weight: 600;
  color: var(--black);
}

ul.accordion-list li p span {
  padding-right: 5px;
}

.accordion-body p {
  font-size: 16px;
  font-weight: 600;
  color: var(--black);
}
/**======================= UTILITY SEC CSS FINISH =======================**/

/**======================= BOTS SEC CSS START =======================**/

.wrapper-box {
  padding: 5px 40px;
  border-radius: 20px;
}

#bots-wrapper-one {
  background: var(--gradient-six);
}

.bots-info-box h3 {
  font-size: 45px;
  font-family: "Infra";
  font-weight: 800;
  color: var(--white);
  text-transform: uppercase;
  line-height: 70px;
}

#bots-wrapper-two {
  background: var(--gradient-seven);
}
#bots-wrapper-three {
  background: var(--gradient-eight);
}
#bots-wrapper-four {
  background: var(--gradient-nine);
}

#bots-wrapper-five {
  background: var(--gradient-ten);
  padding: 64px;
}

.bots-info-box p {
  color: var(--black);
  font-weight: 600;
  line-height: 26px;
  font-size: 16px;
}

.form-box {
  background-color: var(--black);
  padding: 25px 25px;
  border-radius: 20px;
}

.input-group .form-control {
  color: var(--grey);
  background: rgb(34, 34, 47);
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px !important;
  border-radius: 4px;
  font-size: 15px;
  border-color: rgba(187, 191, 202, 0.2);
  min-height: 45px;
}

.input-group .form-control::placeholder {
  color: var(--grey);
  font-size: 14px;
}

.label label {
  font-size: 16px;
  font-family: "Infra";
  color: var(--white);
  font-weight: 500;
}

.submit-button button.submit-button {
  padding: 14px 14px;
  border: none;
  border-radius: 5px;
  background-color: var(--green-light);
  font-size: 15px;
  font-weight: 600;
}
/**======================= BOTS SEC CSS FINISH =======================**/

@media all and (min-width: 1200px) and (max-width: 1399px) {
  .banner-info h1 br {
    display: none;
  }

  .heading-box h2 br {
    display: none;
  }
}

@media all and (min-width: 992px) and (max-width: 1199px) {
  .banner-info h1 {
    font-size: 40px;
    line-height: 53px;
  }
  .banner-info h1 br {
    display: none;
  }

  .banner-info span br {
    display: none;
  }

  ul.button-list li a button.ecosystem-button {
    min-width: 180px;
    min-height: 50px;
    font-size: 17px;
    font-weight: 700;
  }
  .heading-box h2 {
    font-size: 30px;
    line-height: 43px;
  }
  .heading-box h2 br {
    display: none;
  }

  .plan-price {
    flex-direction: column;
  }

  .plan-price h4 {
    padding-bottom: 15px;
    font-size: 15px;
  }

  .bots-info-box h3 {
    font-size: 30px;
    line-height: 50px;
  }
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .banner-info h1 {
    font-size: 30px;
    line-height: 45px;
  }

  .banner-info h1 br {
    display: none;
  }

  .banner-info span br {
    display: none;
  }

  .banner-info span {
    font-size: 16px;
    line-height: 26px;
  }

  .button-list-box ul.button-list {
    gap: 5px;
    justify-content: center;
  }

  ul.button-list li a button.ecosystem-button {
    min-width: 160px;
    min-height: 43px;
    font-size: 14px;
  }

  .inner-wrapper {
    padding: 35px;
  }

  .heading-box h2 br {
    display: none;
  }

  .heading-box h2 {
    font-size: 25px;
    line-height: 37px;
  }

  .chatai-heading h3 {
    font-size: 30px;
  }

  .chatai-heading button.openchatp-btn {
    min-width: 150px;
  }

  .price-box {
    padding: 30px;
  }

  ul.plan-list li {
    font-size: 14px;
  }

  .plan-price {
    flex-direction: column;
  }

  .plan-price h4 {
    font-size: 17px;
    padding-bottom: 16px;
    line-height: 25px;
    text-align: center;
  }

  .about-list-text p {
    font-size: 14px;
  }

  .bots-info-box h3 {
    font-size: 27px;
    line-height: 43px;
  }

  #bots-wrapper-five {
    padding: 35px;
  }
}

@media all and (min-width: 320px) and (max-width: 767px) {
  .banner-info {
    text-align: center;
  }

  .banner-info h1 {
    font-size: 30px;
    line-height: 46px;
  }

  .banner-info h1 br {
    display: none;
  }

  .banner-info span {
    font-size: 16px;
    line-height: 24px;
  }

  .banner-info span br {
    display: none;
  }

  .button-heading {
    text-align: center;
  }

  .button-box {
    flex-direction: column;
  }

  .inner-wrapper {
    padding: 20px !important;
  }

  .heading-box h2 {
    font-size: 20px;
    line-height: 34px;
    text-align: center;
  }

  .heading-box h2 br {
    display: none;
  }
  .button-list-box ul.button-list {
    flex-direction: column;
  }

  .button-list-box ul.button-list li {
    margin-bottom: 15px;
  }

  .chatai-heading h3 {
    font-size: 24px;
    text-align: center;
  }
  .chatai-heading p {
    font-size: 14px;
  }

  .chatai-image {
    margin-bottom: 25px;
  }

  .chatai-heading button.openchatp-btn {
    margin: 0 auto;
  }

  .image-button {
    flex-direction: column;
  }

  .price-box {
    padding: 20px;
  }

  .price-box h3 {
    font-size: 25px;
    padding-bottom: 20px;
    text-align: center;
  }

  ul.plan-list {
    margin-bottom: 35px;
  }

  .plan-price h4 {
    padding-bottom: 14px;
    font-size: 15px;
    text-align: center;
    line-height: 24px;
  }

  .plan-price {
    flex-direction: column;
  }

  .wrapper-box {
    padding: 10px 20px;
  }

  .bots-info-box h3 {
    font-size: 22px;
    line-height: 35px;
    text-align: center;
  }

  #bots-wrapper-five {
    padding: 20px;
  }

  #bots-wrapper-five .bots-info-box {
    margin-bottom: 20px;
  }

  .bots-info-box p br {
    display: none;
  }

  .bots-info-box p {
    font-size: 14px;
    text-align: center;
  }

  .heading h3 {
    font-size: 30px;
  }
}
</style>
