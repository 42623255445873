<template>
  <div>
    <footer class="footer">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xl-6">
            <div class="footer-box">
              <div class="logo mb-4">
                <img
                  src="../assets/logo.png"
                  alt="logo"
                  class="img-fluid"
                  style="max-width: 120px"
                  loading="lazy"
                />
              </div>
              <!--logo-box-->

              <div class="letter-box mb-4">
                <form class="form">
                  <div class="label mb-2">
                    <label> Subscribe To Our Newsletter.</label>
                  </div>
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1"
                      ><button class="subscribe-button">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            style="
                              fill: rgba(0, 0, 0, 1);
                              transform: ;
                              msfilter: ;
                            "
                          >
                            <path
                              d="m20.665 3.717-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42 10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701h-.002l.002.001-.314 4.692c.46 0 .663-.211.921-.46l2.211-2.15 4.599 3.397c.848.467 1.457.227 1.668-.785l3.019-14.228c.309-1.239-.473-1.8-1.282-1.434z"
                            ></path>
                          </svg>
                        </span></button
                    ></span>
                    <input
                      type="text"
                      class="form-control shadow-none"
                      placeholder="Enter your email address......"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </form>
              </div>
              <!--letter-box-->

              <div class="contract-box mb-4 mb-md-3 mb-lg-2">
                <span class="email">
                  <a
                    href="mailto:info@gdcchain.com"
                    class="text-decoration-none"
                  >
                    info@gdcchain.com</a
                  >
                </span>

                <!-- <p class="mb-0">
                  Token Contract -
                  <span>0x1c2641f9574d9288f4ca8d24c4894675bb17cb01</span>
                </p> -->
              </div>
              <!--contract-box-->

              <div class="copyright-box">
                <span>Copyright ® 2023 GDCCAI. All Rights Reserved</span>
              </div>
            </div>
            <!--footer-box-->
          </div>

          <div class="col-md-6 col-lg-6 col-xl-6">
            <div class="social-list">
              <ul class="list">
                <li>
                  <a href="#" class="text-decoration-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="m20.665 3.717-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42 10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701h-.002l.002.001-.314 4.692c.46 0 .663-.211.921-.46l2.211-2.15 4.599 3.397c.848.467 1.457.227 1.668-.785l3.019-14.228c.309-1.239-.473-1.8-1.282-1.434z"
                      ></path>
                    </svg>
                  </a>
                </li>

                <li>
                  <a href="" class="text-decoration-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M21.593 7.203a2.506 2.506 0 0 0-1.762-1.766C18.265 5.007 12 5 12 5s-6.264-.007-7.831.404a2.56 2.56 0 0 0-1.766 1.778c-.413 1.566-.417 4.814-.417 4.814s-.004 3.264.406 4.814c.23.857.905 1.534 1.763 1.765 1.582.43 7.83.437 7.83.437s6.265.007 7.831-.403a2.515 2.515 0 0 0 1.767-1.763c.414-1.565.417-4.812.417-4.812s.02-3.265-.407-4.831zM9.996 15.005l.005-6 5.207 3.005-5.212 2.995z"
                      ></path>
                    </svg>
                  </a>
                </li>

                <li>
                  <a href="" class="text-decoration-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M14.82 4.26a10.14 10.14 0 0 0-.53 1.1 14.66 14.66 0 0 0-4.58 0 10.14 10.14 0 0 0-.53-1.1 16 16 0 0 0-4.13 1.3 17.33 17.33 0 0 0-3 11.59 16.6 16.6 0 0 0 5.07 2.59A12.89 12.89 0 0 0 8.23 18a9.65 9.65 0 0 1-1.71-.83 3.39 3.39 0 0 0 .42-.33 11.66 11.66 0 0 0 10.12 0q.21.18.42.33a10.84 10.84 0 0 1-1.71.84 12.41 12.41 0 0 0 1.08 1.78 16.44 16.44 0 0 0 5.06-2.59 17.22 17.22 0 0 0-3-11.59 16.09 16.09 0 0 0-4.09-1.35zM8.68 14.81a1.94 1.94 0 0 1-1.8-2 1.93 1.93 0 0 1 1.8-2 1.93 1.93 0 0 1 1.8 2 1.93 1.93 0 0 1-1.8 2zm6.64 0a1.94 1.94 0 0 1-1.8-2 1.93 1.93 0 0 1 1.8-2 1.92 1.92 0 0 1 1.8 2 1.92 1.92 0 0 1-1.8 2z"
                      ></path>
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="" class="text-decoration-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M19.633 7.997c.013.175.013.349.013.523 0 5.325-4.053 11.461-11.46 11.461-2.282 0-4.402-.661-6.186-1.809.324.037.636.05.973.05a8.07 8.07 0 0 0 5.001-1.721 4.036 4.036 0 0 1-3.767-2.793c.249.037.499.062.761.062.361 0 .724-.05 1.061-.137a4.027 4.027 0 0 1-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 0 1-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 0 0 8.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 0 1 4.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 0 0 2.556-.973 4.02 4.02 0 0 1-1.771 2.22 8.073 8.073 0 0 0 2.319-.624 8.645 8.645 0 0 1-2.019 2.083z"
                      ></path>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
            <!--social-list-->
          </div>
          <!--col-md-6 col-lg-6 col-xl-6-->
        </div>
        <!--row-->
      </div>
      <!--container-->
    </footer>
    <!--footer-->
  </div>
</template>

<script>
export default {
  name: "FooterComponents",
};
</script>

<style scoped>
.footer {
  background-color: var(--footer-bg);
  padding: 30px 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.footer::before {
  position: absolute;
  content: "";
  bottom: 0;
  right: 0;
  background-image: url(../assets/images/shape.svg);
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  width: 1000px;
  height: 140px;
  z-index: -1;
}

.input-group {
  width: 100%;
  position: relative;
  background: var(--footer-input);
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
  border-radius: 8px;
  max-width: 400px;
}

.input-group .form-control {
  background: transparent;
  background: var(--footer-input);
  border: none;
  font-size: 1rem;
  color: var(--black);
}

.input-group span#basic-addon1 {
  background: transparent;
  border: none;
}

.input-group span#basic-addon1 button.subscribe-button {
  border: none;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
}

.input-group span#basic-addon1 button.subscribe-button span svg {
  width: 20px;
}

.label label {
  font-size: 16px;
  font-weight: 600;
  color: var(--black);
}

.contract-box span.email a {
  font-size: 14px;
  font-weight: 500;
  color: var(--grey);
  display: inline-block;
  padding-bottom: 4px;
}

.contract-box p {
  font-size: 14px;
  color: var(--black);
  font-weight: 500;
}

.copyright-box span {
  font-size: 18px;
  font-weight: 600;
  color: var(--black);
}

.social-list ul.list {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 30px;
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  top: 13px;
}

.social-list ul.list li a {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--black);
  border-radius: 36px;
}

.social-list ul.list li a svg {
  fill: var(--white);
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .footer::before {
    display: none;
  }

  .copyright-box span {
    font-size: 15px;
  }
}

@media all and (min-width: 320px) and (max-width: 767px) {
  .footer::before {
    display: none;
  }
  .footer-box {
    text-align: center;
  }
  .copyright-box span {
    line-height: 35px;
  }
  .social-list ul.list {
    justify-content: center;
  }
}
</style>
